import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-header-small grid grid-cols-1 sm:grid-cols-2 px-10" }
const _hoisted_2 = { class: "col-start-1 flex justify-between sm:justify-start items-center space-x-10 border-b border-gray-400" }
const _hoisted_3 = {
  href: "/",
  class: "flex flex-col justify-center",
  "data-aos": "zoom-out-right"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "hidden sm:flex col-start-2 flex justify-end items-center 2xl:space-x-5 space-x-3 2xl:text-lg font-semibold border-b border-gray-400" }
const _hoisted_6 = { className: "inline-block rounded-full overflow-hidden bg-gray-100 border border-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!$setup.isBOMSApp)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 0,
            "enter-active-class": "animate__animated animate__fadeIn",
            "leave-active-class": "animate__animated animate__fadeOut"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", _hoisted_3, [
                _createElementVNode("img", {
                  class: "h-12 object-contain",
                  src: require('@/assets/images/SJ-logo.png'),
                  alt: "t-recs logo"
                }, null, 8, _hoisted_4)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, [
        _createVNode($setup["TheAvatar"], {
          name: $setup.getNameUser(),
          rounded: ""
        }, null, 8, ["name"])
      ]),
      _createElementVNode("span", null, _toDisplayString($setup.getNameUser()), 1)
    ])
  ]))
}