import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79ded8e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu__sidebar" }
const _hoisted_2 = { class: "menu__sidebar-footer" }
const _hoisted_3 = {
  key: 0,
  class: "animate__animated animate__fadeIn animate__slow"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "animate__animated animate__fadeIn animate__slow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['menu__sidebar-list', $setup.getSubPath().length > 2 && 'withSubPath'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, (item, idx) => {
        return (_openBlock(), _createBlock(_component_el_tooltip, {
          key: idx,
          "hide-after": "100",
          effect: "dark",
          content: item.name,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["LinkSidebar"], {
              to: $setup.truthlyPath(item.path),
              toggle: $setup.toggle,
              icon: item.icon
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "toggle", "icon"])
          ]),
          _: 2
        }, 1032, ["content"]))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      ($setup.isBOMSApp && $setup.route.path !== '/')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass([
          '2xl:text-lg sm:text-xl ml-5 mb-6 p-3 h-12 flex justify-start items-center space-x-2 overflow-hidden hover:bg-cafe-black-50 hover:text-white rounded-xl group',
          $setup.toggle ? 'w-12' : 'w-full',
        ]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.router.push('/')), ["prevent"]))
          }, [
            _createVNode($setup["BaseSvgIcon"], {
              class: "text-cafe-black-50 group-hover:text-white w-6 h-6",
              name: "arrow_back_thick"
            }),
            (!$setup.toggle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Share"))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: _normalizeClass([
          '2xl:text-lg sm:text-xl ml-5 p-3 h-12 flex justify-start items-center space-x-2 overflow-hidden hover:bg-cafe-black-50 hover:text-white rounded-xl group',
          $setup.toggle ? 'w-12' : 'w-full',
        ]),
        onClick: _withModifiers($setup.handleLogout, ["prevent"])
      }, [
        _createVNode($setup["BaseSvgIcon"], {
          class: "text-cafe-black-50 group-hover:text-white w-6 h-6",
          name: "logout"
        }),
        (!$setup.toggle)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Logout"))
          : _createCommentVNode("", true)
      ], 10, _hoisted_4)
    ])
  ]))
}